<template>
  <section class="first-time-buyer">
    <section class="hero">
      <div class="hero-container">
        <h1>Les avantages d’être primo-accédant</h1>
      </div>
    </section>
    <div class="hero-img">
      <img src="@/assets/img/pages/first-time-buyer-1.jpg" alt="Les avantages d’être primo-accédant" />
    </div>
    <article class="grid-container article">
      <div class="article-title fit-content">
        <h3>
          Cette catégorie d’acheteurs, souvent trentenaires, ne se limite pas à ceux qui n’ont
          jamais été propriétaires. Elle inclue un public beaucoup plus large lui aussi éligible aux
          aides spécifiquement dédiées aux ménages désireux de réaliser une première acquisition.
          Explications.
        </h3>
      </div>
      <div class="article-card">
        <card-type-img>
          <template v-slot:content>
            <p>
              Qui est primo-accédant en 2019 ? L’été dernier, le courtier en crédit Cafpi avait
              dressé le portrait- type de ces aspirants à la propriété sur la base des données
              compilées au sein de son réseau d’agences. L’étude le décrivait comme un candidat
              assez jeune (35 ans en moyenne) et bien installé professionnellement (il évolue en CDI
              dans près de 8 cas sur 10).
            </p>
            <p>
              20% d’entre eux recherchent des opportunités pour faire construire un logement neuf,
              souvent à l’écart des grosses agglomérations où les prix sont moins compétitifs qu’en
              périphérie (le dernier indice d’évolution des prix immobiliers publié par
              MeilleursAgents affiche une hausse de +11% en dix ans dans les 50 plus grandes villes
              de France, contre une baisse de -4,4% sur la même période en zone rurale).
            </p>
          </template>
          <template v-slot:img>
            <img
              src="@/assets/img/pages/first-time-buyer-2.jpg"
              alt="Marché de la primo-accession"
            />
          </template>
        </card-type-img>
      </div>
      <div class="article-interest-free-loan fit-content">
        <div>
          <p>
            Pour autant, le marché de la primo-accession ne se résume pas à la demande des ménages
            qui souhaitent acquérir leur première habitation. Aux yeux de la loi, sont également
            considérés comme tels les anciens propriétaires qui ont revendu leur résidence
            principale depuis au moins deux ans et n’en ont, dans ce même laps de temps, racheté
            aucune autre. Si ces deux conditions sont respectées, le porteur d’un nouveau projet
            d’acquisition immobilière est réintégré dans la catégorie « primo-accédant » et jouit,
            de fait comme de droit, des avantages attachés à cette qualité. Elle lui permet par
            exemple de solliciter des prêts subventionnés par l’Etat auprès des banques, et de
            candidater à l’octroi de primes éventuelles débloquées par les pouvoirs publics,
            notamment à l’échelon local (conseils départementaux et communes).
          </p>
        </div>
        <h2>Eligibles au prêt à taux zéro</h2>
        <div class="article-interest-free-loan-img">
          <img
            src="@/assets/img/pages/first-time-buyer-3.jpg"
            alt="Maîtriser les coûts de construction"
          />
        </div>
        <div class="article-interest-free-loan-text">
          <p>
            La plupart des outils proposés sont cumulables entre eux et viennent compléter une
            source de financement classique (un crédit à intérêts fixés sur les prix du marché
            bancaire). Le prêt à taux zéro, comme son nom l’indique, couvre gratuitement une partie
            de l’achat immobilier (jusqu’à 40% dans les grandes agglomérations). D’autres crédits
            sont accordés, sous conditions de ressources, à des taux préférentiels, comme le prêt
            épargne logement (PEL) lié à un placement bancaire préalable, le prêt « action logement
            » financé par les entreprises via des cotisations patronales (voir notre article sur les
            aides pour faire construire).
          </p>
          <p>
            L’ensemble de ces dispositifs, combiné au niveau très bas des taux d’intérêts actuels,
            assure la prééminence des primo-accédants sur le marché immobilier qu’ils contribuent
            largement à porter : leur quote-part dans le volume de crédits accordés était encore
            majoritaire en 2018 (56% du total selon Cafpi).
          </p>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
import CardTypeImg from '@/components/general/CardTypeImg.vue';

export default {
  name: 'first-time-buyer',
  components: {
    CardTypeImg,
  },
};
</script>

<style lang="sass">
.first-time-buyer
  background-color: $medium-bg
  padding-bottom: 6rem
  .hero
    background-color: darken($primary, 5%)
    height: 35rem
    display: flex
    flex-direction: column
    align-items: center
    &-container
      margin-top: 8rem
      width: 60rem
      h1
        color: $white
        text-align: center
    &-img
      display: flex
      flex-direction: row
      justify-content: center
      img
        border-radius: $global-border-radius
        margin-top: -15rem
  .article
    &-title
      display: flex
      justify-content: center
      margin: 4.5rem auto 0 auto
      h3
        text-align: center
    &-card
      margin: 4.5rem auto 0 auto
      p:not(:last-child)
        margin-bottom: 1rem
      a
        font-weight: normal !important
    &-interest-free-loan
      margin: 4.5rem auto 0 auto
      h2
        margin-top: 4.5rem
        text-align: center
        color: $primary
      &-img
        margin-top: 4.5rem
        img
          object-fit: cover
          width: 100%
          border-radius: $global-border-radius
      &-text
        margin-top: 3rem
        p:not(:last-child)
          margin-bottom: 1rem
        a
          @include hyperlink
          text-decoration: none
          font-size: inherit
          font-weight: normal
  .fit-content
    width: 90%;
    max-width: 950px;
  @media (max-width: 768px)
    .hero
      height: 20rem
      &-container
        margin-top: 6rem
        width: 90%
      &-img
        img
          object-fit: cover
          width: 90%
          margin-top: -5rem
    .article
      &-title
        margin: 2.5rem auto 0 auto
      &-card
        margin: 2.5rem auto 0 auto
      &-interest-free-loan
        margin: 3rem auto 0 auto
        &-img
          margin-top: 2.5rem
        &-text
          margin-top: 1.5rem
</style>
